import type {
  Entry,
  EntryCollection,
  NavigatorItem,
  NavigatorItemTitle,
} from '@snapchat/mw-contentful-schema';
import clone from 'lodash-es/clone';
import cloneDeep from 'lodash-es/cloneDeep';
import head from 'lodash-es/head';
import isNil from 'lodash-es/isNil';
import type { FC, PropsWithChildren } from 'react';
import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { userBucketCount } from '../../constants/experiments';
import { UrlParameter } from '../../constants/urlParameters';
import { logInfo } from '../../helpers/logging';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { adler32 } from '../../utils/adler32';
import type { SitewideConfigurationContextProps } from './SitewideConfigurationContext';
import { SitewideConfigurationContext } from './SitewideConfigurationContext';
import type { SitewideConfigurationData } from './SitewideConfigurationQuery';
import { sitewideConfigurationQuery } from './SitewideConfigurationQuery';

type NavigatorItemRichTitle = NavigatorItem & { richTitle?: NavigatorItemTitle };

const isEntry = (value: unknown): value is Entry => {
  return typeof value === 'object' && (value as Entry).sys?.id !== undefined;
};

const isCollection = (value: unknown): value is EntryCollection => {
  return typeof value === 'object' && Array.isArray((value as EntryCollection).items);
};

/**
 * Max depth to prevent infinite recursion. Chose 30 since during testing, we only hit 7 levels
 * deep, so 50 way more than enough.
 */
const maxDepth = 50;

function replaceEntries<T extends Entry>(
  entry: T,
  replacementMap: Record<string, unknown>,
  depth = 0
): T | undefined {
  // We have to clone here because the original entry may be frozen
  let returnVal: T | undefined = clone(entry);

  if (depth > maxDepth) {
    console.error('Max depth reached in replaceEntries');
    return returnVal;
  }

  // If entry is in the replacement map, replace itself with the replacement
  if (entry.sys.id in replacementMap) {
    // If undefined, that means the item was removed
    if (replacementMap[entry.sys.id] === undefined) {
      return undefined;
    }
    returnVal = replacementMap[entry.sys.id] as T;
    // Entry replaced, now run replaceEntries on the new entry in case it has things that also need to be replaced
    return replaceEntries(returnVal, replacementMap, depth + 1);
  }

  // Entry does not need to be replaced, check if it has any nested entries that need to be replaced
  for (const key in returnVal) {
    const value = returnVal[key];

    if (key.endsWith('Collection') && isCollection(value)) {
      // Call replace entries on all the items in the collection, and then filter out undefineds
      const newItems = value.items
        .map(item => replaceEntries(item as T, replacementMap, depth + 1))
        .filter(entry => !!entry);
      returnVal[key] = { ...value, items: newItems };
    } else if (isEntry(value)) {
      // casting this here because we know it's an entry, and undefined is a valid value
      returnVal[key] = replaceEntries(
        value,
        replacementMap,
        depth + 1
      ) as (typeof returnVal)[typeof key];
    }
  }

  return returnVal;
}

/**
 * Provider for the sitewide configuration context. Queries contentful and sets its own value. Holds
 * the values for Navigation Bar, Footer, and Feature Flags.
 */
export const SitewideConfigurationProvider: FC<PropsWithChildren> = ({ children }) => {
  const { experimentData, currentLocale } = useContext(AppContext);

  const { data } = useContentfulQuery<SitewideConfigurationData>(sitewideConfigurationQuery, {
    variables: { locale: currentLocale },
  });

  const getSitewideValues = (
    data?: SitewideConfigurationData
  ): SitewideConfigurationContextProps | undefined => {
    if (!data) return;

    if (!data?.sitewideConfigurationCollection?.items) return;

    const reference = head(data.sitewideConfigurationCollection.items)?.reference;

    if (!reference) return;

    if (reference.__typename === 'SitewideValues') {
      return { sitewideValues: reference };
    }

    if (reference.__typename === 'SitewideExperiment') {
      if (!reference.default) {
        return;
      }

      let finalValues = reference.default;

      const replacementMap: Record<string, Entry | undefined> = {};

      if (!reference.variantsCollection?.items) {
        return { sitewideValues: finalValues };
      }

      // used to only pick the first variant selected for cases
      // where the user is bucketed into multiple variants (e.g. bucket is exactly 50)
      let variantFound = false;

      const seededBucket = experimentData.id + (reference.seed ?? reference.sys.id);
      const userBucket = adler32(seededBucket) % userBucketCount;

      let userTrafficPercentile = (userBucket / userBucketCount) * 100;

      // Allow override the percentile if NOT prod
      if (!Config.isDeploymentTypeProd) {
        // we allow overriding with query param but it was already set on APP_STATE's experimentData
        if (experimentData.population !== undefined) {
          userTrafficPercentile = experimentData.population;
          // if not SSR, we allow overriding with just query param to make dev life easier
          // only if the value is not set in experimentData by server (e.g. running dev:client)
        } else if (!Config.isSSR) {
          const currentUrl = new URL(window.location.href);
          const userBucketParameter = currentUrl.searchParams.get(
            UrlParameter.EXPERIMENTS_USER_BUCKET
          );
          const userBucket = userBucketParameter ? Number.parseInt(userBucketParameter) : undefined;

          if (userBucket !== undefined && userBucket >= 0 && userBucket <= 100) {
            userTrafficPercentile = userBucket;
          }
        }
      }

      // For each variant
      for (const variant of reference.variantsCollection.items) {
        if (variantFound) {
          continue;
        }

        if (
          isNil(variant.trafficEndRange) ||
          isNil(variant.trafficStartRange) ||
          userTrafficPercentile < variant.trafficStartRange ||
          userTrafficPercentile > variant.trafficEndRange
        ) {
          continue;
        }

        variantFound = true;

        // If there are replacements defined
        if (variant.replacementsCollection?.items) {
          // For each replacement
          for (const replacement of variant.replacementsCollection.items) {
            // If both the replacement target and is set
            if (replacement?.replacementTarget?.sys.id) {
              // If somehow replacement has no typename... skip it
              if (!replacement.replacementTarget.__typename) {
                continue;
              }

              if (replacement?.replacement === undefined) {
                replacementMap[replacement.replacementTarget.sys.id] = undefined;
                continue;
              }

              // ULTRA HACK to rename richTitle to title due to gql fragment naming issues
              // TODO: Remove this once the title is fixed/updated to not have rich text
              if (replacement.replacement.__typename === 'NavigatorItem') {
                const finalReplacement: NavigatorItemRichTitle = clone(replacement.replacement);
                finalReplacement.title = finalReplacement.richTitle;

                replacementMap[replacement.replacementTarget.sys.id] = {
                  ...finalReplacement,
                };
              } else {
                // Add the replacement to the map
                replacementMap[replacement.replacementTarget.sys.id] = {
                  ...replacement.replacement,
                };
              }
            }
          }
        }

        logInfo({
          eventCategory: 'Experiment',
          eventAction: 'sitewideExperimentExposure',
          eventLabel: `${reference.analyticsId ?? 'Unknown'}:${variant.analyticsId ?? 'Unknown'}`,
        });
      }

      if (!variantFound) {
        logInfo({
          eventCategory: 'Experiment',
          eventAction: 'sitewideExperimentExposure',
          eventLabel: `${reference.analyticsId ?? 'Unknown'}:default`,
        });
      }

      if (Object.entries(replacementMap).length > 0) {
        finalValues = cloneDeep(reference.default);

        const processedFinalValues = replaceEntries(finalValues, replacementMap);

        if (processedFinalValues) {
          finalValues = processedFinalValues;
        }
      }

      return { sitewideValues: finalValues };
    }

    return;
  };

  const value: SitewideConfigurationContextProps = getSitewideValues(data) || {};

  return (
    <SitewideConfigurationContext.Provider value={value}>
      {children}
    </SitewideConfigurationContext.Provider>
  );
};
