import Cookies from 'js-cookie';
import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { Config } from '../../config';
import { mergeUrlParameters } from '../../helpers/uri';
import { ShopifyContext } from '../ShopifyProvider';

const useCheckoutUrl = (): URL => {
  const { userLocation, getCurrentUrl, currentLocale } = useContext(AppContext);
  const { cart, products, store } = useContext(ShopifyContext);
  const { options } = Config.shopify ?? {};
  const gaCookie = Cookies.get('_ga');
  let queryParams: Record<string, string | boolean> = {
    'attributes[locale]': currentLocale,
    locale: currentLocale,
    'checkout[shipping_address][country]': userLocation.country,
    access_token: store?.storefrontAccessToken ?? '',
  };

  const productsInCart = Object.keys(cart?.lineItems ?? {})
    .map(handle => {
      const contentfulProduct = products.contentful[handle];
      const productHandle = contentfulProduct?.checkoutHandle
        ? contentfulProduct.checkoutHandle
        : handle;

      const productId = products?.shopify[productHandle]?.id ?? '';

      const productVariant = productId.match(/\/(\d+)/);

      if (productVariant) {
        return `${productVariant[1]}:${cart.lineItems[handle]}`;
      }

      return '';
    })
    .join(',');

  if (options?.checkoutQueryParams) {
    queryParams = {
      ...queryParams,
      ...options.checkoutQueryParams,
    };
  }

  if (gaCookie) {
    queryParams['_ga'] = gaCookie;
  }

  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const shopifyUrl = new URL(`https://${store?.domain}/cart/${productsInCart}?${queryString}`);
  mergeUrlParameters(shopifyUrl, new URL(getCurrentUrl()), Config.persistentQueryParams);
  return shopifyUrl;
};

export { useCheckoutUrl };
