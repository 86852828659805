import { CategoryOptInCookie } from '@snapchat/mw-cookie-components/src/components/types';
import { getTopLevelDomain } from '@snapchat/parse-domain';
import Cookies from 'js-cookie';

import { localeCookie } from '../helpers/locale';
import { logEvent, SubscribedEventType } from '../helpers/logging';

// TODO: Rename this category to Locale. The change can happen anywhere, not just from dropdowns.
const logEventCategory = 'Dropdown';
const logEventAction = 'select';

export const updateLocale = (locale: string): void => {
  const url = new URL(window.location.href);

  // we can only set locale cookie when preferences are enabled
  if (Cookies.getJSON(CategoryOptInCookie.Preferences) === true) {
    Cookies.set(localeCookie, locale, {
      // Note: We set the cookie on the base domain, so all websites on the *.snap.com domain
      // use the same cookie. Ditto for snapchat.com.
      // Also note that we use the &lang= parameter to transfer language setting between domains.
      domain: getTopLevelDomain(url.hostname),
      secure: true,
    });
  }

  logEvent({
    subscribedEventType: SubscribedEventType.USER_INTERACTION,
    eventCategory: logEventCategory,
    eventAction: logEventAction,
    eventLabel: `Language: ${locale}`,
  });

  url.searchParams.set('lang', locale);
  window.location.replace(url.pathname + url.search + url.hash);
};
