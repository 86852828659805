import { css, cx } from '@emotion/css';
import type { AnchorHTMLAttributes, FC, PropsWithChildren } from 'react';
import { Children, forwardRef } from 'react';

import {
  boldCss,
  italicsCss,
  orderedListCss,
  paragraphCss,
  primitiveElementClassNames,
  underlineCss,
  unorderedCss,
} from './Primitives.styles';
import type { AnchorComponent } from './Primitives.types';

// =============================================================================
// Markers (b/u/i)
// =============================================================================

export const Bold: FC<PropsWithChildren> = ({ children }) => (
  // TODO: This should be a <b>
  <span className={boldCss}>{children}</span>
);
Bold.displayName = 'Bold';

export const Italics: FC<PropsWithChildren> = ({ children }) => (
  // TODO: This should be an <i>
  <span className={italicsCss}>{children}</span>
);
Italics.displayName = 'Italics';

export const Underline: FC<PropsWithChildren> = ({ children }) => (
  // TODO: This should be a <u>
  <span className={underlineCss}>{children}</span>
);
Underline.displayName = 'Underline';

export const Superscript: FC<PropsWithChildren> = ({ children }) => <sup>{children}</sup>;
Superscript.displayName = 'Superscript';

const listItemContentCss = css`
  margin-left: 6px; /* eyeball guestimate */
`;

// =============================================================================
// Headings (H1 - H6)
// =============================================================================

export const H1: FC<PropsWithChildren> = ({ children }) => (
  <h1 className={cx(primitiveElementClassNames.h1)}>{children}</h1>
);
H1.displayName = 'H1';

export const H2: FC<PropsWithChildren> = ({ children }) => (
  <h2 className={cx(primitiveElementClassNames.h2)}>{children}</h2>
);
H2.displayName = 'H2';

export const H3: FC<PropsWithChildren> = ({ children }) => (
  <h3 className={cx(primitiveElementClassNames.h3)}>{children}</h3>
);
H3.displayName = 'H3';

export const H4: FC<PropsWithChildren> = ({ children }) => (
  <h4 className={cx(primitiveElementClassNames.h4)}>{children}</h4>
);
H4.displayName = 'H4';

export const H5: FC<PropsWithChildren> = ({ children }) => (
  <h5 className={cx(primitiveElementClassNames.h5)}>{children}</h5>
);
H5.displayName = 'H5';

export const H6: FC<PropsWithChildren> = ({ children }) => (
  <h6 className={cx(primitiveElementClassNames.h6)}>{children}</h6>
);
H6.displayName = 'H6';

// =============================================================================
// Paragraphs
// =============================================================================

export const Paragraph: FC<PropsWithChildren> = ({ children }) => (
  <p className={cx(paragraphCss, primitiveElementClassNames.p)}>{Children.toArray(children)}</p>
);

Paragraph.displayName = 'Paragraph';

// =============================================================================
// Lists
// =============================================================================

export const UnorderedList: FC<PropsWithChildren> = ({ children }) => (
  <ul className={cx(unorderedCss, primitiveElementClassNames.ul)}>{children}</ul>
);
UnorderedList.displayName = 'UnorderedList';

export const OrderedList: FC<PropsWithChildren> = ({ children }) => (
  <ol className={cx(orderedListCss, primitiveElementClassNames.ol)}>{children}</ol>
);
OrderedList.displayName = 'OrderedList';

export const ListItem: FC<PropsWithChildren> = ({ children }) => (
  <li>
    <div className={cx(listItemContentCss, primitiveElementClassNames.li)}>{children}</div>
  </li>
);
ListItem.displayName = 'ListItem';

// =============================================================================
// Hyperlink
// =============================================================================

/**
 * Default Anchor implementation. Uses plain `<a>` with no embelishments. Used to be substituted for
 * custom implementations depending on the host application link component choice.
 *
 * The typical override for this is to use a wrapper around the `Link` from `react-router-dom`.
 */
export const Anchor: AnchorComponent = forwardRef<
  HTMLAnchorElement,
  AnchorHTMLAttributes<HTMLAnchorElement>
>((props, ref) => {
  return <a {...props} ref={ref} />;
});
