import { DeploymentType } from '@snapchat/mw-common';
import { BackgroundType, CookieModal as CookiePopupGlobal } from '@snapchat/mw-global-components';
import { BackgroundColor } from '@snapchat/snap-design-system-marketing';
import type React from 'react';
import { useContext } from 'react';

import { AppContext } from '../../AppContext';
import { UrlParameter } from '../../constants/urlParameters';
import {
  logError,
  onMarketingTrackingAccepted,
  onPerformanceTrackingAccepted,
} from '../../helpers/logging';
import { updateLocale } from '../../utils/updateLocale';
import { Feature, useFeatureFlags } from '../FeatureFlags';

export interface CookiePopupProps {
  cookieDomain: string;
  deploymentType: DeploymentType;
  domainName: string;
  googleTagManagerId?: string;
  googleAnalyticsId?: string;
  isClient: boolean;
  backgroundColor?: BackgroundColor;
  globalPrivacyControl?: boolean;
}

const defaultBackgroundColor = BackgroundColor.White;

export const CookiePopup: React.FC<CookiePopupProps> = ({
  cookieDomain,
  deploymentType,
  googleAnalyticsId,
  googleTagManagerId,
  isClient,
  backgroundColor = defaultBackgroundColor,
  globalPrivacyControl,
}) => {
  const { supportedLocales, userLocation, getCurrentUrl } = useContext(AppContext);
  const featureFlags = useFeatureFlags();

  const url = new URL(getCurrentUrl());
  const isCcpa = userLocation.country === 'US' && userLocation.region === 'ca';
  const isGtmDebug = url.searchParams.has(UrlParameter.GOOGLE_TAG_MANAGER_DEBUG);
  const disableGaInCcpa = featureFlags[Feature.CCPA_DISABLE_GTM] === 'true';
  const maybeGtmId = disableGaInCcpa && isCcpa && !isGtmDebug ? undefined : googleTagManagerId;
  const maybeGaId = disableGaInCcpa && isCcpa && !isGtmDebug ? undefined : googleAnalyticsId;

  // Use current domain for local development and in Dev/Staging environments
  if (deploymentType !== DeploymentType.PRODUCTION) {
    cookieDomain = '';
  }

  // Return nothing for SSR
  if (!isClient) return null;

  const backgroundType =
    backgroundColor === BackgroundColor.Black ? BackgroundType.DarkMode : BackgroundType.LightMode;

  return (
    <CookiePopupGlobal
      backgroundType={backgroundType}
      supportedLocales={supportedLocales}
      onLocaleChange={updateLocale}
      cookieDomain={cookieDomain}
      GAID={maybeGaId}
      GTMID={maybeGtmId}
      marketingAnalyticsCallback={() => {
        onMarketingTrackingAccepted().catch(error => logError({ component: 'CookiePopup', error }));
      }}
      performanceAnalyticsCallback={() => {
        onPerformanceTrackingAccepted().catch(error =>
          logError({ component: 'CookiePopup', error })
        );
      }}
      globalPrivacyControl={globalPrivacyControl}
    />
  );
};
