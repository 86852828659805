import type { FC } from 'react';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';

import { AppContext } from '../../AppContext';
import { defaultLocale } from '../../helpers/locale';

interface CanonicalProps {
  /**
   * Array of locales that a page is localized in. Defaults to en-US in case the field is undefined
   * (this happens if the Page has no titles set yet)
   */
  localizedIn?: string[];
}

/**
 * Adds canonical based on whether or not the page is localized in the current locale.
 *
 * In the new paradigm, the canonical link is always the same, regardless of the locale.
 */
export const Canonical: FC<CanonicalProps> = ({ localizedIn: _localizedIn = [defaultLocale] }) => {
  const { getCurrentUrl } = useContext(AppContext);

  const url = new URL(getCurrentUrl());
  url.hash = ''; // remove hash
  url.searchParams.delete('lang'); // Delete any lang params, to let the browser decide.

  return (
    <Helmet>
      <link rel="canonical" href={url.href} />
    </Helmet>
  );
};
