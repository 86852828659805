import type { NormalizedCacheObject } from '@apollo/client';
import createCache from '@emotion/cache';
import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import { AsyncDataController } from '@snapchat/async-data';
import { ClientBrowserFeature } from '@snapchat/client-hints-browser';
import { printWarning } from '@snapchat/self-xss-warning';
import type { History } from 'history';
import { createRoot, hydrateRoot } from 'react-dom/client';
import type { HelmetData } from 'react-helmet-async';

import type { AppProps } from './App';
import { App } from './App';
import type { AppProviderProps, RedirectOptions } from './AppContext';
import { defaultContext } from './AppContext';
import { initializeClientLogging } from './clientonly/loggingInitClient';
import { startClientLogging } from './clientonly/loggingStartClient';
import { Config } from './config';
import type { PageLayoutContextProps } from './context/PageLayoutContext';
import { defaultLocale } from './helpers/locale';
import { getOrCreateApolloClient } from './utils/contentful/ContentfulClientCache';
import { applyUrlOverrides } from './utils/contentful/contentfulUrlOverrides';
import { generateFragmentTypes } from './utils/contentful/generateFragmentTypes';
import { setTracer } from './utils/tracing';
import { BrowserTracer } from './utils/tracing/browserTracer';

interface EsBuildDevError {
  errors: {
    location: {
      file: string;
      line: string;
      column: string;
    };
    text: string;
  }[];
  // TODO: Add warnings here if they're useful.
}

// TODO: Move this into src/browser
async function main() {
  // Initialize logging to: Google, Graphene, Blizzard, etc.
  initializeClientLogging(Config);
  startClientLogging(Config);

  setTracer(new BrowserTracer());

  // Note: APP_STATE is exported when server uses renderHtml.tsx.
  const state: AppProviderProps & {
    pageLayoutContext: PageLayoutContextProps;
  } = window.APP_STATE ?? defaultContext;

  // Note: APOLLO_STATE and GLOBAL_APOLLO_STATE is exported when server uses renderHtml.tsx.
  const apolloCache: NormalizedCacheObject = window.APOLLO_STATE; // OK to be undefined.
  const globalApolloCache: NormalizedCacheObject = window.GLOBAL_APOLLO_STATE; // OK to be undefined.

  const requestUrl = new URLSearchParams(window.location.search);

  // We should be redirecting on the server, but we change the url here for redundancy.
  if (window.location.pathname.startsWith(`/${state.currentLocale}`)) {
    const url = new URL(window.location.href);
    url.pathname = url.pathname.replace(`/${state.currentLocale}`, '');

    if (state.currentLocale !== defaultLocale) {
      url.searchParams.set('lang', state.currentLocale);
    }
    window.history.pushState({}, '', url);
  }

  const contentfulConfigWithOverrides = applyUrlOverrides(Config.contentful, requestUrl);

  // Note: APOLLO_FRAGMENTS is exported when server uses renderHtml.tsx.
  const localApolloFragments =
    window.APOLLO_FRAGMENTS && Object.keys(window.APOLLO_FRAGMENTS).length > 0
      ? window.APOLLO_FRAGMENTS
      : await generateFragmentTypes(contentfulConfigWithOverrides);

  const globalApolloFragments =
    window.GLOBAL_APOLLO_FRAGMENTS && Object.keys(window.GLOBAL_APOLLO_FRAGMENTS).length > 0
      ? window.GLOBAL_APOLLO_FRAGMENTS
      : await generateFragmentTypes(Config.contentfulGlobal);

  const apolloClient = getOrCreateApolloClient(state.currentLocale, [
    contentfulConfigWithOverrides,
    localApolloFragments,
    apolloCache,
  ]);

  const globalApolloClient = getOrCreateApolloClient(state.currentLocale, [
    Config.contentfulGlobal,
    globalApolloFragments,
    globalApolloCache,
  ]);

  let asyncDataControllerCache = new Map();

  if (window.ASYNC_DATA_CONTROLLER_CACHE) {
    asyncDataControllerCache = new Map(Object.entries(window.ASYNC_DATA_CONTROLLER_CACHE ?? {}));
  }

  const asyncDataController = new AsyncDataController({
    cache: asyncDataControllerCache,
    // 1 hour 30 second default expiry time so that cached results returned from CDN
    // do not expire immediately.
    defaultExpiryTimeMs: 3600e3 + 30e3,
    clock: Date.now,
  });

  const browserFeatures = new ClientBrowserFeature();

  let history: History<unknown> | undefined;

  const clientAppProps: AppProps = {
    ...state,
    getCurrentUrl: () => window.location.href,
    routerContext: {},
    helmetContext: {} as HelmetData,
    apolloClient,
    globalApolloClient,
    onRedirect: (location, options?: RedirectOptions) => {
      // Short circuit full urls.
      const newTab = options?.newTab;

      if (location.startsWith('http')) {
        window.open(location, newTab ? '_blank' : '_self');
        return;
      }

      // If we have access to React history object, we can update the state without
      // a full page reload.
      if (history && !newTab) {
        // For internal links, we can push the state to the react history object.
        history.push(location);
        // But we do also want to add an item to the browser history.
        window.history.pushState(null, '', new URL(location, window.location.href));
        return;
      }
      // no history so need to do full reload.
      window.open(location, newTab ? '_blank' : '_self');
    },
    onHistory: capturedHistory => {
      history = capturedHistory;
    },
    browserFeatures,
    asyncDataController,
  };

  // print xss warning in console
  if (Config.isClient) {
    printWarning(state.currentLocale);
  }

  // Reads the stylesheet from `<style data-emotion="marketing-web-emotion ...">...</style>` */
  const cache = createCache({ key: 'marketing-web-emotion' });

  // Server should pre-render the initial body using SSR, so we can call hydrate instead of render
  const jsx = (
    <EmotionCacheProvider value={cache}>
      <App {...clientAppProps} />
    </EmotionCacheProvider>
  );

  // Note: <main> element is specified in index.ejs.
  const rootElement = document.querySelector('main');

  if (!rootElement) {
    console.error('HTML must contain a <main> element.');
  } else if (rootElement.hasChildNodes() ?? false) {
    hydrateRoot(rootElement, jsx);
  } else {
    // If SSR failed, we render fully. Without this SSR failures result in blank pages.
    createRoot(rootElement).render(jsx);
  }

  // ==========================================================================
  // Client Recompile Dev Reload
  // ==========================================================================

  let errorCount = 0;

  if (Config.isLocal && Config.compilationMode === 'development') {
    const serverStatus = new EventSource('/esbuild', {});

    serverStatus.addEventListener('error', () => {
      console.error(`/esbuild server disconnected.`);
      errorCount++;

      if (errorCount > 1e3) {
        serverStatus.close();

        console.info(
          'Esbuild could not connnect after a 1000 attempts. Closing connection. Please reload.'
        );
      }
      // TODO: Figure out if we want to close this. I.e. calling serverStatus.close();
      // But that breaks the live reload if you restart dev server which isn't desirable in all
      // circumstances.
    });

    serverStatus.addEventListener('change', event => {
      const data = JSON.parse(event.data) as EsBuildDevError;

      if (data.errors?.length) {
        data.errors.forEach(error => {
          const location = error.location;
          const path = `${location.file}:${location.line}:${location.column}`;

          console.error(` 'Build failed. Not reloading.', ${error.text} (${path})`);
        });
      } else {
        console.info('Served content changed. Reloading');
        location.reload();
      }
    });
  }
}

main().catch(error => console.error(error));
