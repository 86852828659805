import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Locale } from 'contentful';
import type { RefObject } from 'react';
import { createContext } from 'react';

import type { PageLayoutContextProps } from './context/PageLayoutContext';
import { FooterView, HeaderView } from './context/PageLayoutContext';

export interface UserLocation {
  country: string;
  region: string;
}
export type RedirectOptions = {
  isPermanent?: boolean;
  newTab?: boolean;
};

export interface AppProviderProps {
  currentLocale: string;
  supportedLocales: Record<string, Locale>;
  userLocation: UserLocation;
  isRTL: boolean;
  getCurrentUrl: () => string;
  onRedirect?: (location: string, options?: RedirectOptions) => void;
  isSlowConnection?: boolean;
  /** Custom site data. Used for passing server-rendered info into the app. */
  siteData?: Record<string, unknown>;
  /** Data used to determine experiment variants. */
  experimentData: {
    /**
     * `id` is a md5 hashed value of the users web client id (cookie value or IP if rejected) this
     * is appended with the experiment seeds to generate a unique experiment bucket.
     */
    id: string;
    /**
     * Population is an override for the experiment population. This is used to override the
     * experiment population for a specific user. This is used for testing purposes only via
     * contentful and for the CI.
     */
    population?: number;
  };

  /**
   * Setter for custom site data.
   *
   * DO NOT USE. This forces a page re-render. Instead copy this context into another local context
   * and change the value there.
   *
   * TODO: Deprecate this after SPS 2023.
   */
  setSiteData: (siteData: Record<string, unknown>) => void;
  /** Reference for the header portal. */
  headerPortalRef?: RefObject<HTMLElement>;
  globalApolloClient?: ApolloClient<NormalizedCacheObject>;
}

export const defaultContext: AppProviderProps & {
  pageLayoutContext: PageLayoutContextProps;
} = {
  currentLocale: 'en-US',
  supportedLocales: {
    'en-US': {
      code: 'en-US',
      name: 'English (United States)',
      default: true,
      fallbackCode: null,
      sys: {
        id: '[Redacted]',
        type: 'Locale',
        version: 1,
      },
    },
  },
  userLocation: {
    country: 'US',
    region: 'ca',
  },
  isRTL: false,
  getCurrentUrl: () => 'https://marketing-web-platform.appspot.com/',
  setSiteData: () => undefined,
  experimentData: {
    id: '',
  },
  pageLayoutContext: {
    footerView: FooterView.FULL_FOOTER,
    headerView: HeaderView.FULL_HEADER,
  },
};

export const AppContext = createContext<AppProviderProps>(defaultContext);
