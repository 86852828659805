import type { FC } from 'react';
import { useContext, useMemo } from 'react';
import { __RouterContext } from 'react-router';

import { useExperiment } from '../../components/Experiment';
import { PageShallow } from '../../components/Page/PageShallow';
import { SlugContext } from '../../components/Slug/SlugContext';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { NoMatchDefault } from './NoMatchDefault';
import type { Custom404CollectionData } from './query';
import { custom404CollectionQuery } from './query';

export const NoMatch: FC = () => {
  // For SSR we should set the status code to 404 so it can still gives
  // an http error code. Based on https://v5.reactrouter.com/web/guides/server-rendering/404-401-or-any-other-status
  const { staticContext } = useContext(__RouterContext);

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  const { decideExperiment } = useExperiment();
  const { data } = useContentfulQuery<Custom404CollectionData>(custom404CollectionQuery);

  const custom404Slug = data?.slugCollection.items?.[0];
  const custom404OrExperiment = custom404Slug?.page;

  const experimentResult = useMemo(
    () => {
      return custom404OrExperiment
        ? decideExperiment(custom404OrExperiment, { logImpression: true })
        : undefined;
    },
    // Don't want "decideExperiment" to re-run to avoid multiple impression logs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [custom404OrExperiment]
  );

  const custom404 = experimentResult?.decision;

  if (!data) {
    return null; // Loading
  }

  return custom404 ? (
    <SlugContext.Provider
      value={{
        isShareable: false,
        replacements: experimentResult.replacements,
      }}
    >
      <section data-testid="mwp-404-page">
        <PageShallow {...custom404} />
      </section>
    </SlugContext.Provider>
  ) : (
    <NoMatchDefault />
  );
};

NoMatch.displayName = 'NoMatch';
