import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'citizen.snap.com',
  localeFilter: ['en-US'],
  redirects: {
    hostRedirects: {},
  },
  globalNavProps: {
    defaultGroupKey: 'safety',
  },
  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.CITIZEN_SNAP },
      {
        vendor: LoggingVendor.GOOGLE,
        googleTagManagerId: 'GTM-PB72WQ3',
        googleAnalyticsId: 'UA-41740027-66',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'citizen-snap-com',
        dsn: 'https://95ca42addd564fd6a8c36cfe84bc195d@sentry.sc-prod.net/88',
      },
    ],
  },
  tracing: {
    samplingRate: 1, // Set to the default value of 1 for testing.
  },
};
