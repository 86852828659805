import type { LatestPostItem } from '@snapchat/snap-design-system-marketing';
import { FormattedMessage, LatestPosts } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { useFormatDate } from '../../hooks/useFormatDate';
import type { ContentfulSysProps } from '../../types/contentful';
import { Anchor } from '../Anchor';
import { CallToAction } from '../CallToAction';
import { useExperiment } from '../Experiment/useExperiments';
import type { LatestPostBlockData } from './query';
import { latestPostBlockQuery } from './query';

export interface LatestPostBlockProps extends ContentfulSysProps {
  preChildren?: ReactNode;
  postChildren?: ReactNode;
}

export const LatestPostBlock: FC<LatestPostBlockProps> = props => {
  const { formatDate } = useFormatDate();
  const { decideExperiment } = useExperiment();

  const { data } = useContentfulQuery<LatestPostBlockData, ContentfulIdVariable>(
    latestPostBlockQuery,
    {
      variables: { id: props.sys.id },
    }
  );

  if (data) {
    const {
      latestPosts: { title, maxPosts, backgroundColor, cta, anchorId },
      slugCollection,
    } = data;

    const items: LatestPostItem[] = Array(maxPosts)
      .fill(0)
      .map((_: void, index: number) => {
        const slug = slugCollection.items[index]!;
        const dateFormatted = formatDate(
          slug.postedDate ? slug.postedDate : slug.sys.firstPublishedAt,
          {
            month: 'long',
            day: '2-digit',
            year: 'numeric',
            weekday: 'long',
          }
        );
        const author = slug.author && (
          <FormattedMessage
            id="authorByline"
            values={{
              author: slug.author.name,
            }}
            defaultMessage="By <b>{author}</b>"
          />
        );

        const date = dateFormatted && (
          <FormattedMessage
            id="postedOn"
            values={{ date: dateFormatted }}
            defaultMessage="on {date}"
          />
        );

        if (slug.page.__typename === 'PageAsset') {
          return {
            title: (
              <Anchor href={slug.slug} key={slug.slug}>
                {slug.page?.media?.title}
              </Anchor>
            ),
            author,
            date,
          };
        } else if (slug.page.__typename === 'Page' || slug.page.__typename === 'Experiment') {
          const { decision: page } = decideExperiment(slug.page, { logImpression: false });

          return {
            title: (
              <Link to={slug.slug} key={slug.slug}>
                {page.title}
              </Link>
            ),
            author,
            date,
            category: page.category?.title,
          };
        } else {
          throw new Error('Unknown page type.');
        }
      });

    const ctaElement = cta && <CallToAction key={cta.sys.id} {...cta} />;
    return (
      <LatestPosts
        anchorId={anchorId}
        title={title}
        items={items}
        cta={ctaElement}
        backgroundColor={backgroundColor}
        preChildren={props.preChildren}
        postChildren={props.postChildren}
      />
    );
  }

  return null;
};

LatestPostBlock.displayName = 'LatestPostBlock';
