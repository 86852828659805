import { getTopLevelDomain } from '@snapchat/parse-domain';

/**
 * Function uses passed in parameter value if specified. Otherwise, it will use the top level domain
 * (if it can be retrieved). As a final option, it will use the browser default value (empty
 * string).
 *
 * NOTE: the browser default is not inheritable by subdomains.
 *
 * @param inputCookieDomain [Optional] the explicitly configured cookie domain to use. If this is
 *   specified it is what is returned by the function.
 * @returns String, either the input value or the parsed top level domain. Example: ar.snap.com =>
 *   snap.com
 */
export const getCookieDomain = (inputCookieDomain?: string): string => {
  // Use input value if specified
  if (inputCookieDomain) return inputCookieDomain;

  // Use top level domain (ar.snap.com => snap.com)
  const hostName = (window?.location?.hostname ?? '').toLowerCase();
  const topLevelDomain = getTopLevelDomain(hostName);
  // Return top level domain or empty string
  return topLevelDomain;
};
