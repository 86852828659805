import type { CookieCategoryType } from '../../generated/contentful-types';
import { DesignAgnosticToggle } from '../designAgnostic';
import { CategoryDisplayMode } from '../types';
import { CookieRichText } from './CookieRichText';

interface Props {
  title: string;
  cookieCategories: readonly CookieCategoryType[];
  categoriesState: Record<string, boolean>;
  updateCategoriesState: (key: string, value: boolean) => void;
  activeToggleLabel: string;
  inactiveToggleLabel: string;
  /** Flag to control rendering categories - some are only visible on the settings page. */
  isModal?: boolean;
}

export const CookieCategories: React.FC<Props> = ({
  title,
  cookieCategories,
  categoriesState,
  updateCategoriesState,
  activeToggleLabel,
  inactiveToggleLabel,
  isModal,
}) => {
  return (
    <>
      <h2 className="cookie-title">{title}</h2>
      {cookieCategories.map(category => {
        if (isModal && category.displayMode === CategoryDisplayMode.SettingsOnly) {
          return null;
        }

        // Show inactive text by default
        let categoryLabel = inactiveToggleLabel;
        // For essential categories that users can't disable - show active text
        if (category.isEssential && !category.enableToggle) categoryLabel = activeToggleLabel;
        // User has enabled the toggle - show active text
        if (categoriesState[category.categoryCookieName]) categoryLabel = activeToggleLabel;
        return (
          <div data-testid="mwp-cookie-category-description" key={category.sys.id}>
            <div className="category-border">
              <div className="category-title-container">
                <h5 className="category-title">{category.title}</h5>
                <p className="category-status">{categoryLabel}</p>
                {category.enableToggle === true && (
                  <div className="category-toggle">
                    <DesignAgnosticToggle
                      id={`${category.sys.id}-slider`}
                      isChecked={!!categoriesState[category.categoryCookieName]}
                      onToggle={() =>
                        updateCategoriesState(
                          category.categoryCookieName,
                          !categoriesState[category.categoryCookieName]
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <CookieRichText
                className="category-description"
                document={category.description.json}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};
