import head from 'lodash/head';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { logEvent, SubscribedEventType } from '../../helpers/logging';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { NoMatch } from '../../pages/NoMatch';
import { useExperiment } from '../Experiment/useExperiments';
import { PageShallow } from '../Page/PageShallow';
import { Redirect } from '../Redirect/Redirect';
import type { SlugCollectionData } from './slugCollectionQuery';
import { slugCollectionQuery } from './slugCollectionQuery';
import { SlugContext } from './SlugContext';

// Note: this needs the slash prepended to override multi level paths
const notFoundPath = '/404';

export const SlugCollection: FC<{ slug: string }> = props => {
  const { decideExperiment } = useExperiment();
  const { data } = useContentfulQuery<SlugCollectionData, { slug: string }>(slugCollectionQuery, {
    variables: {
      slug: props.slug,
    },
  });

  const slugMatch = data ? head(data?.slugCollection.items) : undefined;
  const pageOrExperiment = slugMatch?.page;

  const experimentResult = useMemo(
    () => {
      return pageOrExperiment
        ? decideExperiment(pageOrExperiment, { logImpression: true })
        : undefined;
    },
    // Don't want "decideExperiment" to re-run to avoid multiple impression logs.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pageOrExperiment]
  );

  useEffect(() => {
    // Expose page level experiment arm id so release tests are aware of what experiment
    // (if any) was chosen
    if (!experimentResult?.experimentId) return;

    const mainElement = document.getElementsByTagName('main')[0]!;
    mainElement.setAttribute('data-experiment-id', experimentResult.experimentId);

    experimentResult.variantId &&
      mainElement.setAttribute('data-experiment-arm-id', experimentResult.variantId);
  }, [experimentResult?.experimentId, experimentResult?.variantId]);

  const page = experimentResult?.decision;

  if (!data) {
    return null; // Loading.
  }

  if (!page || !slugMatch) {
    logEvent({
      eventCategory: 'NoMatch',
      eventAction: '404-redirect',
      eventLabel: props.slug,
      subscribedEventType: SubscribedEventType.INTERNAL,
    });

    return <Redirect path={notFoundPath} />;
  }

  if (page.__typename === 'PageAsset') {
    if (page.media?.url) {
      // TODO: we have to click back twice now because we are on the same tab.
      // Need a better implementation to render "go back to previous page" with
      // message that a page was opened on another tab. https://jira.sc-corp.net/browse/ENTWEB-8682
      return <Redirect path={page.media.url} newTab={false} />;
    }

    return <NoMatch />;
  }

  if (page.__typename === 'Redirect') {
    logEvent({
      eventCategory: 'Redirect',
      eventAction: 'page-redirect',
      eventLabel: page.slug?.slug ?? page.externalUrl,
      subscribedEventType: SubscribedEventType.INTERNAL,
    });

    if (page.slug?.slug) {
      // redirect to home
      if (page.slug.slug === 'home') {
        return <Redirect path={''} newTab={false} isPermanent={page.isPermanent} />;
      }

      // Adding "/" for the onRedirect in Redirects
      return <Redirect path={`/${page.slug.slug}`} newTab={false} isPermanent={page.isPermanent} />;
    }

    if (page.externalUrl) {
      return <Redirect path={page.externalUrl} newTab={false} isPermanent={page.isPermanent} />;
    }

    return <NoMatch />;
  }

  let publishedAt: Date | undefined = undefined;

  if (slugMatch.sys.publishedAt) {
    publishedAt = new Date(slugMatch.sys.publishedAt);
  }

  const postedDateStr = slugMatch.postedDate ?? slugMatch.sys.firstPublishedAt;
  const postedDate = postedDateStr ? new Date(postedDateStr) : undefined;
  return (
    <SlugContext.Provider
      value={{
        publishedAt,
        postedDate,
        isShareable: slugMatch.shareable ?? false,
        replacements: experimentResult.replacements,
      }}
    >
      <PageShallow {...page} />
    </SlugContext.Provider>
  );
};

SlugCollection.displayName = 'SlugCollection';
